<template>
  <div>
    <Draggable
      v-model="localItems"
      handle=".drag-activator"
      @end="
        $emit('update:items', localItems);
        $emit('reordered', localItems);
      "
    >
      <CollapseItem
        :key="item[itemKey]"
        v-for="(item, index) in localItems"
        :text="item[itemText]"
        :class="{ 'collapse-item-margin': level > 0 }"
        :opened="item.opened"
        @collapse-toggle="$emit(
          'collapse-toggle',
          {
            item: item,
            value: $event
          }
        )"
      >
        <template v-slot:prepend>
          <v-icon v-if="orderable" class="drag-activator">
            mdi-drag-horizontal
          </v-icon>
          <LineButton
            v-if="addOption"
            @click.native="$emit(
              'add-button-clicked',
              {
                parentItem: parentItem,
                previousItem: index > 0 ? localItems[index - 1] : null,
                index: index
              }
            )"
          />
          <LineButton
            v-if="index === localItems.length - 1 && addOption"
            bottom
            @click.native="$emit(
              'add-button-clicked',
              {
                parentItem: parentItem,
                previousItem: item,
                index: index + 1
              }
            )"
          />
        </template>
        <template v-slot:content>
          <TreeView
            v-show="item.children && item.children.length"
            :items.sync="item.children"
            :item-key="itemKey"
            :item-text="itemText"
            :level="level + 1"
            :parent-item="item"
            :orderable="orderable"
            :add-option="addOption"
            :edit-option="editOption"
            :delete-option="deleteOption"
            class="mt-2 mb-2"
            @collapse-toggle="$emit('collapse-toggle', $event)"
            @reordered="$emit('reordered', $event)"
            @add-button-clicked="$emit('add-button-clicked', $event)"
            @edit-button-clicked="$emit('edit-button-clicked', $event)"
            @delete-button-clicked="$emit('delete-button-clicked', $event)"
          />
        </template>
        <template v-if="!item.children || !item.children.length" v-slot:open-button >
          <v-icon
            :disabled="!addOption"
            class="mx-2"
            @click="$emit(
              'add-button-clicked',
              {
                parentItem: item
              }
            )"
          >
            mdi-plus
          </v-icon>
        </template>
        <template v-slot:actions>
          <v-icon
            v-if="editOption"
            dark
            class="mx-2 hidden-option"
            color="primary"
            @click="$emit(
              'edit-button-clicked',
              {
                parentItem: parentItem,
                item: item
              }
            )"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="deleteOption"
            dark
            class="mx-2 hidden-option"
            color="red"
            @click="$emit(
              'delete-button-clicked',
              {
                parentItem: parentItem,
                item: item
              }
            )"
          >
            mdi-trash-can
          </v-icon>
        </template>
      </CollapseItem>
    </Draggable>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import CollapseItem from "@/views/partials/collapse/CollapseItem.vue";
import LineButton from "@/views/partials/buttons/LineButton.vue";

export default {
  name: 'TreeView',

  components: {
    Draggable,
    CollapseItem,
    LineButton
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    itemKey: {
      type: String,
      default: 'id'
    },

    itemText: {
      type: String,
      default: 'text'
    },

    level: {
      type: Number,
      default: 0
    },

    parentItem: {
      type: Object,
      required: false
    },

    orderable: {
      type: Boolean,
      default: false
    },

    addOption: {
      type: Boolean,
      default: false
    },

    editOption: {
      type: Boolean,
      default: false
    },

    deleteOption: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localItems: this.items
    }
  },

  watch: {
    items: {
      handler: function (val) {
        this.localItems = val
      },
      deep: true
    }
  },

  methods: {
    //
  }
}
</script>

<style lang="scss" scoped>
.drag-activator {
  cursor: move;
}

.hidden-option {
  display: none;
}

:deep(.collapse-item:hover > .hidden-option) {
  display: inline-block;
}

.collapse-item-margin {
  margin-left: 32px;
}

@media screen and (max-width: 900px) {
  .collapse-item-margin {
    margin-left: 16px;
  }
}

@media screen and (max-width: 500px) {
  .collapse-item-margin {
    margin-left: 8px;
  }
}
</style>