<template>
  <v-dialog
    :value="saveDialog"
    persistent
    max-width="400px"
    @keydown.esc="closeSaveDialog"
    @keydown.enter="saveMappingValue"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          item.id ?
            $t('MAPPINGS.EDIT_MAPPING_VALUE') :
            $t('MAPPINGS.ADD_MAPPING_VALUE')
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="mappingValueForm"
            v-model="validMappingValueForm"
            @submit.prevent
        >
          <div v-if="groupName" class="group-line">
            {{ $t('MAPPINGS.GROUP_LABEL') }}:
            <b>{{ groupName }}</b>
          </div>
          <v-text-field
            ref="mappingValueInput"
            :label="$t('MAPPINGS.VALUE_LABEL')"
            v-model="mappingValue.value"
            :rules="[validations.required()]"
            :error-messages="error"
            @input="clearValidation"
          />
          <v-autocomplete
            v-if="parentItems.length"
            v-model="mappingValue.parent_id"
            clearable
            :label="$t('MAPPINGS.PARENT_LABEL')"
            placeholder="N/A"
            :items="parentItems"
            item-value="id"
            item-text="value"
            @change="mappingValue.order = null"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text :disabled="isProcessing" @click="closeSaveDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text :disabled="isProcessing" @click="saveMappingValue">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/views/partials/form/Validations.vue";
import {
  CREATE_MAPPING_VALUE,
  UPDATE_MAPPING_VALUE
} from "@/store/mappingValues.module";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      default: () => ({})
    },

    groupName: {
      type: String,
      default: null
    },

    parentItems: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    value() {
      this.saveDialog = this.value;

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.mappingValueInput.focus();
        }, 100);

        this.clearValidation();
      });
    },

    item: {
      handler() {
        this.mappingValue = this.item;
      },
      deep: true
    }
  },

  data() {
    return {
      saveDialog: this.value,
      mappingValue: this.item,
      validMappingValueForm: false,
      isProcessing: false,
      error: '',
      validations: validations
    }
  },

  methods: {
    closeSaveDialog() {
      this.$emit('input', false);
      this.$emit('close');
    },

    saveMappingValue() {
      this.$refs.mappingValueForm.validate();

      if (!this.validMappingValueForm) {
        return;
      }

      if (this.mappingValue.group_value_id == 0) {
        this.mappingValue.group_value_id = null;
      }

      const method = this.item.id ?
        UPDATE_MAPPING_VALUE :
        CREATE_MAPPING_VALUE;

      this.isProcessing = true;

      this.$store.dispatch(method, {
        id: this.mappingValue.id,
        mapping_id: this.mappingValue.mapping_id,
        value: this.mappingValue.value,
        group_value_id: this.mappingValue.group_value_id,
        parent_id: this.mappingValue.parent_id,
        order: this.mappingValue.order
      })
        .then((mappingValue) => {
          this.item.id ?
            this.$emit('mapping-value-updated', mappingValue) :
            this.$emit('mapping-value-created', mappingValue);

          this.closeSaveDialog();
        })
        .catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        }).finally(() => {
          this.isProcessing = false;
        });
    },

    clearValidation() {
      this.error = "";
      this.$refs.mappingValueForm.resetValidation();
    },
  }
}
</script>

<style lang="scss" scoped>
.group-line, .parent-line {
  font-size: 1.3rem;
  padding: 16px 0 10px;
}

:deep(.v-input__slot) {
  padding: 0 !important;
}
</style>