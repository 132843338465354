<template>
  <div>
    <Portlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
               {{ $t("MAPPINGS.EDIT_VALUES") }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="buttons-tab">
              <v-btn
                color="mr-4"
                @click="handleExpandHideAllClick"
              >
                {{ $t('MAPPINGS.EXPAND_HIDE_ALL') }}
              </v-btn>
              <v-btn
              color="mr-4"
              v-on:click="importMappingValues()"
              v-if="hasPermissions('mapping-values-create')"
              >Import Mapping Values
            </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="upload"
              >
              <v-btn
                class="mr-4 primary"
                @click="handleDownloadClick()"
              >
                {{ $t('GENERAL.LABELS.DOWNLOAD_BUTTON') }}
              </v-btn>
            </div>
                <v-tooltip left color="warning">
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="handlePageRefresh"
                    >
                    mdi-refresh
                    </v-icon>
                    </template>
                    <span>
                    {{ $t('GENERAL.LABELS.REFRESH_DATA') }}
                    </span>
                    </v-tooltip>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat :key="pageCartKey">
                <v-card-text>
                  <template v-if="mappingValues.length && mappingValues[0].is_group_value">
                    <Draggable
                      v-model="mappingValues"
                      handle=".drag-activator"
                      @end="handleReorder(mappingValues)"
                    >
                      <CollapseItem
                        :key="groupValue.id"
                        v-for="groupValue in mappingValues"
                        :bordered="false"
                        class="mt-3 mb-3"
                        :opened="groupValue.opened"
                        @collapse-toggle="handleCollapseToggle(groupValue, $event)"
                      >
                        <template v-slot:prepend>
                          <v-icon v-if="hasPermissions('mapping-values-edit')" class="drag-activator mx-2">
                            mdi-drag-horizontal
                          </v-icon>
                        </template>
                        <template v-slot:text>
                          <span class="group-name">{{ groupValue.value }}</span>
                        </template>
                        <template v-slot:content>
                          <TreeView
                            v-show="groupValue.children && groupValue.children.length"
                            :items.sync="groupValue.children"
                            item-text="value"
                            :level="1"
                            :orderable="hasPermissions('mapping-values-edit')"
                            :add-option="hasPermissions('mapping-values-create')"
                            :edit-option="hasPermissions('mapping-values-edit')"
                            :delete-option="hasPermissions('mapping-values-delete')"
                            class="mt-2 mb-2"
                            @collapse-toggle="handleCollapseToggle($event.item, $event.value)"
                            @reordered="handleReorder($event)"
                            @add-button-clicked="handleAddButtonClicked($event, groupValue)"
                            @edit-button-clicked="handleEditButtonClicked($event, groupValue)"
                            @delete-button-clicked="handleDeleteButtonClicked($event, groupValue)"
                          />
                        </template>
                        <template v-if="!groupValue.children || !groupValue.children.length" v-slot:open-button >
                          <v-icon
                            class="mx-2"
                            :disabled="!hasPermissions('mapping-values-create')"
                            @click="handleAddButtonClicked(
                              {},
                              groupValue
                            )"
                          >
                            mdi-plus
                          </v-icon>
                        </template>
                      </CollapseItem>
                    </Draggable>
                  </template>
                  <TreeView
                    v-else
                    :items.sync="mappingValues"
                    item-text="value"
                    :orderable="hasPermissions('mapping-values-edit')"
                    :add-option="hasPermissions('mapping-values-create')"
                    :edit-option="hasPermissions('mapping-values-edit')"
                    :delete-option="hasPermissions('mapping-values-delete')"
                    @collapse-toggle="handleCollapseToggle($event.item, $event.value)"
                    @reordered="handleReorder($event)"
                    @add-button-clicked="handleAddButtonClicked"
                    @edit-button-clicked="handleEditButtonClicked"
                    @delete-button-clicked="handleDeleteButtonClicked"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="warning"
                    @click="$router.push({ name: 'mappings' })"
                  >
                    {{ $t('GENERAL.LABELS.BACK_BUTTON') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </Portlet>
    <AddEditMappingValueDialog
      v-model="saveDialog"
      :item="mappingValue"
      :group-name="mappingValue.groupValue?.value"
      :parent-items="mappingGroupedValues[mappingValue.groupValue?.value || '']"
      @close="clearMappingValueData"
      @mapping-value-created="handleMappingValueCreated"
      @mapping-value-updated="handleMappingValueUpdated"
    />
    <Confirm
      v-model="deleteConfirm"
      :content="$t('GENERAL.DELETE_CONFIRM')"
      @confirm="deleteMappingValue"
      @decline="deleteConfirm = false"
    />
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { SHOW_SNACK } from "@/store/snack.module";
import Portlet from "@/views/partials/content/Portlet.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import CollapseItem from "@/views/partials/collapse/CollapseItem.vue";
import TreeView from "@/views/partials/treeview/TreeView.vue";
import userStateService from "@/common/userState.service.js";
import AddEditMappingValueDialog from "@/views/pages/mappings/partials/AddEditMappingValueDialog.vue";
import {
  GET_MAPPING_VALUES_TREE,
  GET_MAPPING_VALUES_GROUPED,
  REORDER_MAPPING_VALUES,
  DELETE_MAPPING_VALUE,
  DOWNLOAD_MAPPING_VALUES,
  UPLOAD_MAPPING_VALUES
} from "@/store/mappingValues.module";

export default {
  components: {
    Portlet,
    Draggable,
    Confirm,
    CollapseItem,
    TreeView,
    AddEditMappingValueDialog
  },

  data() {
    return {
      pageCartKey: 1,
      tab: "tab-1",
      saveDialog: false,
      validMappingValueForm: false,
      openedItems: {},
      mappingValue: {
        id: null,
        mapping_id: this.$route.params.mappingId,
        value: '',
        group_value_id: null,
        parent_id: null,
        groupValue: null,
        order: null
      },
      mappingValueForDeleteData: null,
      mappingValues: [],
      mappingGroupedValues: [],
      mappingGroupValues: [],
      isFetched: false,
      isProcessing: false,
      expandButtonLoading: false,
      deleteConfirm: false
    };
  },

  mounted() {
    this.getMappingValues();
    this.getMappingGroupedValues();
  },

  methods: {
    getMappingValues() {
      this.isProcessing = true;

      return this.$store.dispatch(
        GET_MAPPING_VALUES_TREE,
        this.$route.params.mappingId
      ).then((response) => {
        this.mappingValues = response;
        this.prepareOpenedAttribute();
        this.isProcessing = false;
        this.isFetched = true;
      });
    },

    getMappingGroupedValues() {
      this.$store.dispatch(GET_MAPPING_VALUES_GROUPED, this.$route.params.mappingId)
        .then((response) => {
          this.mappingGroupedValues = response;
        });
    },

    // Adds is collapse open attribute to each item
    // based on user previous state if there is any
    prepareOpenedAttribute() {
      this.openedItems = userStateService.getState(
        `mapping-values.opened-items-${this.$route.params.mappingId}`,
        {}
      );

      this.setOpenedItems(this.mappingValues);
    },

    setOpenedItems(mappingValues) {
      mappingValues.forEach((value) => {
        value.opened = this.openedItems[value.id] || (!(value.children && value.children.length));

        if (value.children && value.children.length) {
          this.setOpenedItems(value.children);
        }
      });
    },

    handleCollapseToggle(item, collapseValue) {
      item.opened = collapseValue;
      this.openedItems[item.id] = collapseValue;

      userStateService.setState(
        `mapping-values.opened-items-${this.$route.params.mappingId}`,
        this.openedItems
      );
    },

    handleExpandHideAllClick() {
      if (this.isAllExpanded(this.mappingValues)) {
        this.expandHideAll(this.mappingValues, false);
      } else {
        this.expandHideAll(this.mappingValues, true);
      }

      this.pageCartKey++;

      userStateService.setState(
        `mapping-values.opened-items-${this.$route.params.mappingId}`,
        this.openedItems
      );
    },

    isAllExpanded(mappingValues) {
      let isAllExpanded = true;

      for (let i = 0; i < mappingValues.length; i++) {
        if (mappingValues[i].opened !== true) {
          isAllExpanded = false;
          break;
        }

        if (mappingValues[i].children && mappingValues[i].children.length) {
          isAllExpanded = this.isAllExpanded(mappingValues[i].children);
          if (!isAllExpanded) {
            break;
          }
        }
      }

      return isAllExpanded;
    },

    expandHideAll(mappingValues, expandValue) {
      mappingValues.forEach((value) => {
        value.opened = expandValue;
        this.openedItems[value.id] = expandValue;

        if (value.children && value.children.length) {
          this.expandHideAll(value.children, expandValue);
        }
      });
    },

    handleReorder(items) {
      let orders = {};

      items.forEach((value, index) => {
        orders[value.id] = index + 1;
      });

      this.$store.dispatch(REORDER_MAPPING_VALUES, { orders: orders })
        .catch(() => {
          this.isProcessing = true;

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleAddButtonClicked(event, groupValue = null) {
      if (groupValue) {
        this.mappingValue.groupValue = groupValue;
        this.mappingValue.group_value_id = groupValue.id;
      }

      if (event.parentItem) {
        this.mappingValue.parent_id = event.parentItem.id;
      }

      this.mappingValue.order = event.previousItem ?
        event.previousItem.order + 1 :
        1;

      this.saveDialog = true;
    },

    handleEditButtonClicked(event, groupValue = null) {
      this.mappingValue.id = event.item.id;
      this.mappingValue.value = event.item.value;

      if (groupValue) {
        this.mappingValue.groupValue = groupValue;
      }

      if (event.parentItem) {
        this.mappingValue.parent_id = event.parentItem.id;
      }

      this.saveDialog = true;
    },

    handleMappingValueCreated(mappingValue) {
      this.setMappingValueOpened(mappingValue);
      this.getMappingValues();
      this.getMappingGroupedValues();
      this.clearMappingValueData();

      this.$store.dispatch(SHOW_SNACK, {
        type: 'warning',
        message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
      });
    },

    handleMappingValueUpdated() {
      this.getMappingValues();
      this.getMappingGroupedValues();
      this.clearMappingValueData();

      this.$store.dispatch(SHOW_SNACK, {
        type: 'warning',
        message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
      });
    },

    setMappingValueOpened(mappingValue) {
      mappingValue.opened = true;
      this.openedItems[mappingValue.id] = true;

      if (mappingValue.group_value_id) {
        this.openedItems[mappingValue.group_value_id] = true;
      }

      if (mappingValue.parent_id) {
        this.openedItems[mappingValue.parent_id] = true;
      }

      userStateService.setState(
        `mapping-values.opened-items-${this.$route.params.mappingId}`,
        this.openedItems
      );
    },

    handleDeleteButtonClicked(event, groupValue = null) {
      this.mappingValueForDeleteData = event;
      if (groupValue && !this.mappingValueForDeleteData.parentItem) {
        this.mappingValueForDeleteData.parentItem = groupValue;
      }

			this.deleteConfirm = true;
    },

    deleteMappingValue() {
      this.isProcessing = true;

      this.$store
        .dispatch(DELETE_MAPPING_VALUE, this.mappingValueForDeleteData.item.id)
        .then(() => {
          if (this.mappingValueForDeleteData.parentItem == null) {
            this.mappingValues = this.mappingValues.filter((item) => {
              return item.id !== this.mappingValueForDeleteData.item.id;
            });
          } else {
            this.mappingValueForDeleteData.parentItem.children =
              this.mappingValueForDeleteData.parentItem.children.filter((item) => {
                return item.id !== this.mappingValueForDeleteData.item.id;
              });
          }

          this.pageCartKey++;

          this.$store.dispatch(SHOW_SNACK, {
            type: 'warning',
            message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
          });
        }).finally(() => {
          this.isProcessing = false;
          this.mappingValueForDeleteData = null;
        });
    },

    clearMappingValueData() {
      this.mappingValue.id = null;
      this.mappingValue.value = '';
      this.mappingValue.group_value_id = null;
      this.mappingValue.parent_id = null;
      this.mappingValue.groupValue = null;
      this.mappingValue.order = null;
    },
    handlePageRefresh() {
      this.getMappingValues();
      this.getMappingGroupedValues();
      this.$forceUpdate();
    },
    handleDownloadClick() {
      this.isProcessing = true;

      this.$store
        .dispatch(DOWNLOAD_MAPPING_VALUES, this.$route.params.mappingId)
        .then((resource) => {
            location.href = resource.url;
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    // XXX: once clicked needs a page refresh
    importMappingValues() {
        this.$refs.uploader.click();
    },
    upload(e){
        
        this.file = e ? e.target.files[0] : this.file;
        
        if (!this.file) {
            return;
        }
        let formData = new FormData();
        formData.append('file', this.file);
        this.isProcessing = true;
        this.$store
          .dispatch(UPLOAD_MAPPING_VALUES, {params:formData})
          .then((resource) => {
            this.$store.dispatch(SHOW_SNACK, {
            message: "Mapping Values upload successfuly.",
          });
        })
        .catch((response) => {
        })
        .finally(() => {
          this.isProcessing = false;
          this.$refs.uploader.type='text';    
          this.$refs.uploader.type='file'; 
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.group-name {
  font-weight: bold;
  font-size: 1.1rem;
}

.drag-activator {
  cursor: move;
}
</style>