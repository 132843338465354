<template>
  <div>
    <div class="collapse-item d-flex align-items-center" :class="{ 'collapse-item-bordered': bordered }">
      <slot name="prepend" />
      <slot name="open-button">
        <v-icon class="mx-2" @click="toggleCollapse">
          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </slot>
      <slot name="text">
        {{ text }}
      </slot>
      <v-spacer />
      <slot name="actions" />
    </div>
    <!-- <v-expand-transition> -->
      <div v-if="isOpen" class="collapse-item-content">
        <slot name="content" />
      </div>
    <!-- </v-expand-transition> -->
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    bordered: {
      type: Boolean,
      default: true
    },

    opened: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: this.opened || false
    }
  },

  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen
      this.$emit('collapse-toggle', this.isOpen);
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-item {
  position: relative;
  padding: 0 10px;
  min-height: 38px;
}

.collapse-item-bordered {
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 0 1px white;
}

.without-hover-effect:before {
  opacity: 0 !important;
}
</style>