<template>
  <div class="add-button-line" :class="`add-button-line-${bottom ? 'bottom' : 'top'}`">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div class="add-button-square" v-bind="attrs" v-on="on">
          <div class="add-button-icon">
            <v-icon color="white" dense>
              mdi-plus
            </v-icon>
          </div>
        </div>
      </template>
      <span>{{ $t('GENERAL.LABELS.ADD_NEW_BELOW') }}</span>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.add-button-line {
  position: absolute;
  left: -10px;
  width: calc(100% + 10px);
  height: 2px;
  pointer-events: none; //Remove this if you want hover on the line

  &:hover {
    background-color: #4bade8;
    cursor: pointer;

    .add-button-square {
      top: -7px;
      left: -7px;
      width: 16px;
      height: 16px;
      border-radius: 10%;
      background-color: #4bade8;

      .add-button-icon {
        display: block;
        position: absolute;
        top: -2.5px;
        left: -1.5px;
      }
    }
  }

  .add-button-square {
    position: absolute;
    left: 0px;
    top: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #e0e0e0;
    pointer-events: auto; //Remove this if you want hover on the line

    .add-button-icon {
      display: none;
    }
  }
}

.add-button-line-top {
  top: -1px;
}

.add-button-line-bottom {
  bottom: -1px;
}
</style>