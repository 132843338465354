var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Draggable',{attrs:{"handle":".drag-activator"},on:{"end":function($event){_vm.$emit('update:items', _vm.localItems);
      _vm.$emit('reordered', _vm.localItems);}},model:{value:(_vm.localItems),callback:function ($$v) {_vm.localItems=$$v},expression:"localItems"}},_vm._l((_vm.localItems),function(item,index){return _c('CollapseItem',{key:item[_vm.itemKey],class:{ 'collapse-item-margin': _vm.level > 0 },attrs:{"text":item[_vm.itemText],"opened":item.opened},on:{"collapse-toggle":function($event){return _vm.$emit(
        'collapse-toggle',
        {
          item: item,
          value: $event
        }
      )}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.orderable)?_c('v-icon',{staticClass:"drag-activator"},[_vm._v(" mdi-drag-horizontal ")]):_vm._e(),(_vm.addOption)?_c('LineButton',{nativeOn:{"click":function($event){return _vm.$emit(
            'add-button-clicked',
            {
              parentItem: _vm.parentItem,
              previousItem: index > 0 ? _vm.localItems[index - 1] : null,
              index: index
            }
          )}}}):_vm._e(),(index === _vm.localItems.length - 1 && _vm.addOption)?_c('LineButton',{attrs:{"bottom":""},nativeOn:{"click":function($event){return _vm.$emit(
            'add-button-clicked',
            {
              parentItem: _vm.parentItem,
              previousItem: item,
              index: index + 1
            }
          )}}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('TreeView',{directives:[{name:"show",rawName:"v-show",value:(item.children && item.children.length),expression:"item.children && item.children.length"}],staticClass:"mt-2 mb-2",attrs:{"items":item.children,"item-key":_vm.itemKey,"item-text":_vm.itemText,"level":_vm.level + 1,"parent-item":item,"orderable":_vm.orderable,"add-option":_vm.addOption,"edit-option":_vm.editOption,"delete-option":_vm.deleteOption},on:{"update:items":function($event){return _vm.$set(item, "children", $event)},"collapse-toggle":function($event){return _vm.$emit('collapse-toggle', $event)},"reordered":function($event){return _vm.$emit('reordered', $event)},"add-button-clicked":function($event){return _vm.$emit('add-button-clicked', $event)},"edit-button-clicked":function($event){return _vm.$emit('edit-button-clicked', $event)},"delete-button-clicked":function($event){return _vm.$emit('delete-button-clicked', $event)}}})]},proxy:true},(!item.children || !item.children.length)?{key:"open-button",fn:function(){return [_c('v-icon',{staticClass:"mx-2",attrs:{"disabled":!_vm.addOption},on:{"click":function($event){return _vm.$emit(
            'add-button-clicked',
            {
              parentItem: item
            }
          )}}},[_vm._v(" mdi-plus ")])]},proxy:true}:null,{key:"actions",fn:function(){return [(_vm.editOption)?_c('v-icon',{staticClass:"mx-2 hidden-option",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.$emit(
            'edit-button-clicked',
            {
              parentItem: _vm.parentItem,
              item: item
            }
          )}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.deleteOption)?_c('v-icon',{staticClass:"mx-2 hidden-option",attrs:{"dark":"","color":"red"},on:{"click":function($event){return _vm.$emit(
            'delete-button-clicked',
            {
              parentItem: _vm.parentItem,
              item: item
            }
          )}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]},proxy:true}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }